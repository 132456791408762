import { useCallback, useState, useEffect, useRef, createRef } from "react";
import CustNavbar from "../navbar/CustNavbar";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./Aboutus.scss";
import promoicons from "../assets/promoicons.png";
import stats from "../assets/stats.png";
import wae from "../assets/wae.png";
import Footer from "../footer/Footer";
import globe from "../assets/globe.png";
import globed from "../assets/Global Coverage.svg";
import icon from "../assets/right arrow icon.png";
import call from "../assets/Call.svg";
import coverage from "../assets/Global Coverage.svg";
import integration from "../assets/Integration.svg";
import geolock from "../assets/Money Transfor Worldwide.mp4";
import speak from "../assets/Speak Straight.svg";
import team from "../assets/Team.svg";
import { ReactLenis, useLenis } from "lenis/react";

function Aboutus() {
  return (
    <ReactLenis root>
      <Container fluid className="aboutus">
        <CustNavbar
          className="nav"
          style={{
            backgroundColor: "#ffffff",
            color: "#000000",
            backgroundOpacity: "1",
          }}
        />
        <Row className="mainBar">
					<video autoPlay loop muted className="background-video">
						<source src={geolock} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
					<div className="content">
						<h1>
							Manage your <span className="orange">Payments</span> from anywhere at any time.
						</h1>
						<p>Global payment networks for eCommerce, technology, and more.</p>
						<button className="getStarted">
							<a href="/contact">Get Started With Api</a>
							<img src={icon} />
						</button>
					</div>
				</Row>
        <Row className="whoarewe">
        <img className="mainbarimg" src={promoicons} />
          <p className="head">Who Are We</p>
          <p className="para">
            A payment gateway service that lets companies of all sizes use a
            single API to take payments from clients anywhere and wherever they
            choose,which enables e-commerce companies to transform their banking
            and merchant services with a full Payment Ecosystem and enter new
            markets precisely.
          </p>
          <Row className="cards">
            <Col md={6} className="card">
            <img src={team} />
              <h4>A team that believes in your ideas!</h4>
              <p>
                We learn your motivation to act as a catalyst for your business
                goals and help you stand out from your competitors.
              </p>
            </Col>
            <Col md={6} className="card">
            <img src={globed} />
              <h4>We serve you the way you run </h4>
              <p>
                Flexible integration for your business that enables your
                customers to pay the way they wish to. We facilitate their
                transaction experience maintaining the highest level of
                security standards.
              </p>
            </Col>
          </Row>
        </Row>
        <Row className="experiencethediff">
          <h1>Experience the Difference</h1>
          <p>
            "Elevating standards, exceeding expectations—find out why we stand
            out."
          </p>
          <Row className="cardsexp">
            <Col md={3} className="cardexp">
            <img src={globed} />
              <h4>Global Coverage</h4>
              <p>Global Coverage with the most popular payment methods.</p>
            </Col>
            <Col md={3} className="cardexp">
            <img src={integration} />
              <h4>Hassle free Integration</h4>
              <p>Hassle free integration to bring you live within 24 hours.</p>
            </Col>
            <Col md={3} className="cardexp">
            <img src={speak} />
              <h4>Speak Straight</h4>
              <p>We speak straight without being rude.</p>
            </Col>
            <Col md={3} className="cardexp">
            <img src={call} />
              <h4>Don’t play behind Email</h4>
              <p>
                Don’t play behind Email rather we connect directly via phone
              </p>
            </Col>
          </Row>
        </Row>

				<Row className="paymentSpeed">
					<img className="globeImg" src={globe}></img>
					<h1>
						Payments intended to <span className="orange">Speed up</span> <br /> your company
					</h1>
					<p>We’d love to give you a tour of the platform and show you how we can help your business scale faster</p>
					<button className="getStarted">
						<a href="">Get in Touch</a>
						<img src={icon} />
					</button>
				</Row>
				<Footer style={{ borderRadius: "64px", margin: "4vw 7vw", width: "auto" }} />
			</Container>
		</ReactLenis>
	);
}

export default Aboutus;
