import React from "react";
import styled, { css } from "styled-components";
import "./CustNavbar.scss";
import icon from "../assets/iconwithtxt.png";
import logo from "../assets/Logo.png";
function CustNavbar({ style }) {
  return (
    <div className="customNav" id="customNav">
      <div className="elements" style={style}>
        <div className="left">
          <a href="/">
            <img src={logo} alt="logo"></img>
          </a>
        </div>
        <div className="right">
          <ul>
            <li>
              <a href="/solutions">Solutions</a>
            </li>
            <li>
              <a href="/developers">Developer</a>
            </li>
            <li>
              <a href="/aboutus">About Us</a>
            </li>
            <li>
              <a href="/insights">Insight</a>
            </li>
            <li className="orangeButton">
              <a  href="/contact">Talk to us</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CustNavbar;
