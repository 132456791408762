import { useCallback, useState, useEffect, useRef, createRef } from "react";
import { useLocation } from "react-router-dom";
import "../blogs/Blogs.scss";
import axios from "axios";
import { useSessionStorage } from "../../hooks/useLocalStorage";
import CustNavbar from "../navbar/CustNavbar";
import Footer from "../footer/Footer";

function Blogs() {
  const [jsonResponse, setJsonResponse] = useState({});
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [error, setError] = useState(null); // State to manage errors
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
      const [localValue, setLocalValue, removeLocalValue] = useSessionStorage('blogJson');
  useEffect(() => {
      if (localValue) {
        setJsonResponse(localValue);
        setLoading(false);
      }; // Run effect when localValue changes
  }, [localValue]);
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div>
      <CustNavbar
          className="nav"
          style={{
            backgroundColor: "#ffffff",
            color: "#000000",
            backgroundOpacity: "1",
          }}
        />
      {jsonResponse?.blogs
        .filter((blog) => blog.blog_id === id)
        .map((blog) => (
          <div className="blogDiv">
            <div className="imageDiv">
              <img src={blog.image} alt="NA"></img>
            </div>
            <h1 className="blogHeading">{blog.blog_header}</h1>
            <h6>{blog.blog_mini_desc}</h6>
            <p className="blogContent">{blog.blog_text}</p>
          </div>
        ))}
         <Footer style={{ borderRadius: "64px" }} />
    </div>
  );
}

export default Blogs;
