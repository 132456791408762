import { useCallback, useState, useEffect, useRef, createRef } from "react";
import CustNavbar from "../navbar/CustNavbar";
import "./Developer.scss";
import { Container, Row, Col, Button } from "react-bootstrap";
import image from "../assets/3x.png";
import frame1 from "../assets/Frame1.png";
import frame2 from "../assets/Frame2.png";
import frame3 from "../assets/Frame3.png";
import frame4 from "../assets/Frame4.png";
import icon from "../assets/right arrow icon.png";
import Footer from "../footer/Footer";

function Developer() {
  return (
    <div className="developers">
      <CustNavbar
        className="nav"
        style={{
          backgroundColor: "#ffffff",
          color: "#000000",
          backgroundOpacity: "1",
        }}
      />
      <Container fluid>
        <Row className="mainBar">
          <Col md={7} className="left">
            <h1>
              Resources & tooling for <span className="orange">Developers.</span>
            </h1>
            <p>Explore our guides and examples to integrate the API.</p>
            <button className="getStarted">
              <a href="/contact">Get Started With Api  <img src={icon} /></a>
             
            </button>
          </Col>
          <Col md={5} className="right">
            <img src={image} />
          </Col>
        </Row>
      </Container>
      <Container fluid className="pggateway">
        <h1>Integrate Payment Gateway</h1>
        <p>Detailed documentation and references for web checkout integration.</p>
        <Row className="cards">
          <Col className="card">
            <img src={frame1} /> <p>Offers multiple payment workflows</p>
          </Col>
          <Col className="card">
            <img src={frame2} /> <p>Professional Tech support</p>
          </Col>
          <Col className="card">
            <img src={frame3} /> <p>Easy setup and support cURL</p>
          </Col>
          <Col className="card">
            <img src={frame4} /> <p>Checkout Customisations</p>
          </Col>
        </Row>
        <button className="getStarted">
          <a href="/contact">Get Started With Api <img src={icon} /></a>
          
        </button>
      </Container>
      <Footer style={{ borderRadius: "64px", margin: "4vw 7vw", width: "auto" }} />
    </div>
  );
}

export default Developer;
