import { useCallback, useState, useEffect, useRef, createRef } from "react";
import CustNavbar from "../navbar/CustNavbar";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./Admin.scss";
import axios from "axios";

function Admin() {
  const [login, setLogin] = useState(false);
  const [formData, setFormData] = useState({});

  const [data, setData] = useState( [
    ]
);
  const [select, setSelect] = useState("events");

  function verifyUser(evt) {
    const req = {
      username: evt.target.username.value,
      password: evt.target.password.value,
    };
    axios.post("/api/getuser/", req).then((res) => {
      if (res.data.status === "success") {
        setLogin(true);
      }
    });
  }

  function getEvents() {
    axios.get("/api/events/getallevents/").then((res) => {
      console.log(res);
      setData(res.data?.events);
    });
  }
  function getBlogs() {
    axios.get("/api/blogContent/getAllBlogs/").then((res) => {
      console.log(res);
      setData(res.data?.blogs);
    });
  }

  useEffect(() => {
    if (select === "events") {
      setFormData({
        event_name: "",
        event_date: "",
        event_desc: "",
        image: null,
        enable: 0,
      });
      getEvents();
    } else if (select === "blogs") {
      getBlogs();
      setFormData({
        blog_header: "",
        blog_mini_desc: "",
        blog_text: "",
        image: null,
        enable: 0,
      });
    }
  }, [select]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    

    if (select === "events") {
      const form = new FormData();
      form.append("event_name", formData.event_name);
      form.append("event_date", formData.event_date);
      form.append("event_desc", formData.event_desc);
      form.append("enable", formData.enable);
      if (formData.image) {
        form.append("image", formData.image);
      }
      if (formData.id) {
        form.append("event_id", formData.id); // Pass the event_id in the request
      }
      // Update or create event based on whether id is present
      const url = formData.id
        ? "/api/events/updateEvent/"
        : "/api/events/createEvent/";
      await axios.post(url, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // Refresh data after submission
      await getEvents();
      // Reset form
      setFormData({
        id: "",
        event_name: "",
        event_date: "",
        event_desc: "",
        image: null,
        enable: 0,
      });
    } else {
      const form = new FormData();
      form.append("blog_header", formData.blog_header);
      form.append("blog_mini_desc", formData.blog_mini_desc);
      form.append("blog_text", formData.blog_text);
      form.append("enable", formData.enable);
      if (formData.image) {
        form.append("image", formData.image);
      }
      if (formData.blog_id) {
        form.append("blog_id", formData.blog_id); // Pass the event_id in the request
      }
      // Update or create event based on whether id is present
      const url = formData.blog_id
        ? "/api/blogContent/updateBlog/"
        : "/api/blogContent/createBlog/";
      await axios.post(url, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // Refresh data after submission
      await getBlogs();
      // Reset form
      setFormData({
        blog_id: "",
        blog_header: "",
        blog_mini_desc: "",
        blog_text: "",
        image: null,
        enable: 0,
      });
    }
  };
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleEdit = (item) => {
    debugger;
    setFormData(item);
  };

  const handleChangeSelect = (event) => {
    setSelect(event.target.value);
  };
  return (
    <>
      {!login && (
        <form
          className="infodetails"
          onSubmit={(event) => {
            event.preventDefault();
            verifyUser(event);
          }}
        >
          <div className="name">
            <input type="text" name="username" placeholder="Username"></input>
            <input type="text" name="password" placeholder="Password"></input>
          </div>
          <div className="submitdiv">
            <button className="submit" type="submit">
              Submit
            </button>
          </div>
        </form>
      )}
      {login && (
        <div>
          <div>
            <label>
              Choose an option:
              <select value={select} onChange={handleChangeSelect}>
                <option value="events">Events</option>
                <option value="blogs">Blogs</option>
              </select>
            </label>
            <p>You selected: {select}</p>
          </div>

          {select === "events" && (
            <div>
              <h1>Events</h1>
              <form onSubmit={handleSubmit}>
                <input type="hidden" name="id" value={formData.id} />
                <input
                  type="text"
                  name="event_name"
                  value={formData.event_name}
                  onChange={handleChange}
                  placeholder="Event Name"
                  required
                />
                <input
                  type="text"
                  name="event_date"
                  value={formData.event_date}
                  onChange={handleChange}
                  placeholder="Event Date"
                  required
                />
                <input
                  type="text"
                  name="event_desc"
                  value={formData.event_desc}
                  onChange={handleChange}
                  placeholder="Event Description"
                  required
                />
                <input
                  type="text"
                  name="enable"
                  value={formData.enable}
                  onChange={handleChange}
                  placeholder="Event Enable"
                  required
                />
                <input type="file" name="image" onChange={handleChange} />
                <button type="submit">Submit</button>
              </form>
              <table>
                <thead>
                  <tr>
                    <th>event_name</th>
                    <th>event_date</th>
                    <th>event_desc</th>
                    <th>image</th>
                    <th>Enable</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data !== undefined &&
                    data.map((item) => (
                      <tr key={item.id}>
                        <td>{item.event_name}</td>
                        <td>{item.event_date}</td>
                        <td>{item.event_desc}</td>
                        <td>
                          <img src={item.image} alt={item.name} width="50" />
                        </td>
                        <td>{item.enable}</td>
                        <td>
                          <button onClick={() => handleEdit(item)}>Edit</button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}

          {select === "blogs" && (
            <div>
              <h1>Blogs</h1>
              <form onSubmit={handleSubmit}>
                <input type="hidden" name="id" value={formData.id} />
                <input
                  type="text"
                  name="blog_header"
                  value={formData.blog_header}
                  onChange={handleChange}
                  placeholder="Blog Name"
                  required
                />
                <input
                  type="text"
                  name="blog_mini_desc"
                  value={formData.blog_mini_desc}
                  onChange={handleChange}
                  placeholder="Blog Date"
                  required
                />
                <input
                  type="text"
                  name="blog_text"
                  value={formData.blog_text}
                  onChange={handleChange}
                  placeholder="Blog Description"
                  required
                />
                <input
                  type="text"
                  name="enable"
                  value={formData.enable}
                  onChange={handleChange}
                  placeholder="Blog Enable"
                  required
                />
                <input type="file" name="image" onChange={handleChange} />
                <button type="submit">Submit</button>
              </form>
              <table>
                <thead>
                  <tr>
                    <th>Blog Header</th>
                    <th>Blog Mini Desc</th>
                    <th>Blog Text</th>
                    <th>Image</th>
                    <th>Enable</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data !== undefined &&
                    data.map((item) => (
                      <tr key={item.blog_id}>
                        <td>{item.blog_header}</td>
                        <td>{item.blog_mini_desc}</td>
                        <td>{item.blog_text}</td>
                        <td>
                          <img src={item.image} alt={item.name} width="50" />
                        </td>
                        <td>{item.enable}</td>
                        <td>
                          <button onClick={() => handleEdit(item)}>Edit</button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Admin;
