import logo from "./logo.svg";
import "./App.css";
import Main from "./components/mainpage/Main";
import "lenis/dist/lenis.css";
import Contact from "./components/contact/Contact.js";
import Blogs from "./components/blogs/Blogs.js";
import Developer from "./components/developer/Developer.js";
import Aboutus from "./components/aboutus/Aboutus.js";
import Solution from "./components/solutions/Solution.js";
import Admin from "./components/admin/Admin.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Insights from "./components/insights/Insights.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/insights",
    element: <Insights />,
  },
  {
    path: "/blogs",
    element: <Blogs />,
  },
  {
    path: "/developers",
    element: <Developer />,
  },
  {
    path: "/aboutus",
    element: <Aboutus />,
  },
  {
    path: "/solutions",
    element: <Solution />,
  },

  {
    path: "/admin",
    element: <Admin />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
