import { useCallback, useState, useEffect, useRef, createRef } from "react";
import CustNavbar from "../navbar/CustNavbar";
import "./Solution.scss";
import { ReactLenis, useLenis } from "lenis/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/src/ScrollTrigger";
import { Container, Row, Col, Button } from "react-bootstrap";
import bitcoin from "../assets/bitcoin.png";
import frameExp from "../assets/FrameExport.png";
import apm from "../assets/apm.png";
import icon from "../assets/right arrow icon.png";
import Footer from "../footer/Footer";
function Solution() {
  useEffect(() => {
    gsap.to("#leftGsap", {
      scrollTrigger: {
        trigger: ".cardprocessing",
        start: "top 80%",
        end: "bottom 70%",
        // markers: true,
        scrub: true,
        toggleActions: "play reverse play reverse",
        onUpdate: (self) => {
          const progress = self.progress;
          document.querySelector(
            "#cardProcessingImage"
          ).style.transform = ` translate3d(0vw, ${
            12 - progress * 12
          }vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
        },
      },
    });
  });
  return (
    <ReactLenis root>
      <div className="solution">
        <CustNavbar
          className="nav"
          style={{
            backgroundColor: "#ffffff",
            color: "#000000",
            backgroundOpacity: "1",
          }}
        />
        <Container className="mainContainer" fluid>
          <Row className="mainBar">
            <Col md={7} className="left">
              <h1>
                <span className="orange">iGaming Payment</span> <br />
                Solutions
              </h1>
              <p>
                Discover secure, seamless, and tailored payment processing for
                high-risk industries with the right High-Risk payment provider
                for growth.
              </p>
              <button className="getStarted">
                <a href="/contact">
                  Get Started With Api{"   "}
                  <img src={icon} />
                </a>
              </button>
            </Col>
            <Col md={5} className="right">
              <img src={bitcoin} />
            </Col>
          </Row>
          <Row className="cardprocessing">
            <Col md={6} className="left">
              <h1>
                <span className="orange">Card Processing</span> <br />
                Solutions
              </h1>
              <p>
                Cardpaymentz offers global business empowerment through seamless
                card payments, high approval rates, and vast international
                coverage, promoting sustained growth.
              </p>
            </Col>
            <Col md={6} className="right" id="cardProcessingImage">
              <img src={frameExp} />
            </Col>
          </Row>
          <Row className="apm">
            <h1>
              Alternative Payment Methods <br />
              <span className="orange">(APMs)</span>
            </h1>
            <p>
              Accept local payment methods and boost acceptance rates. With
              dynamic FX conversion, cross-border transactions, and support for
              multiple alternative payment methods via single API, allow your
              users in emerging markets to pay they know how.{" "}
            </p>
            <img src={apm} />
          </Row>
        </Container>
        <Footer style={{ borderRadius: "64px" }} />
      </div>
    </ReactLenis>
  );
}

export default Solution;
