import React, { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/src/ScrollTrigger";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./SlideContent.scss";
import process from "../assets/process.mp4";
import globe from "../assets/globe-solid.svg";
import analytics from "../assets/chart-pie-solid.svg";
import server from "../assets/server-solid.svg";
function SlideContent() {
  useEffect(() => {
    const scrollVideo = document.getElementById("scrollVideo");
    gsap.to(".slideUpAnimation", {
      scrollTrigger: {
        trigger: ".slideUpAnimation",
        start: "top 30%",
        end: "bottom 80%",
        //markers: true,
        scrub: true,
        toggleActions: "play reverse play reverse",

        onUpdate: (self) => {
          // Calculate the video progress based on scroll progress
          const progressV = self.progress; // Get the scroll progress (0 to 1)
          if (
            isFinite(scrollVideo.currentTime) &&
            scrollVideo.currentTime >= 0 &&
            scrollVideo.currentTime <= scrollVideo.duration
          ) {
            scrollVideo.currentTime = progressV * scrollVideo.duration; // Set video time based on scroll progress
          }
        },
      },
    });

    gsap.to(".slideUpCont", {
      scrollTrigger: {
        trigger: ".slideup1",
        start: "top 80%",
        end: "bottom 80%",
        //markers: true,
        scrub: true,
        toggleActions: "play reverse play reverse",
        onUpdate: (self) => {
          const progress = self.progress;
          document.querySelector(".slideup1").style.top = `${
            72 - progress * 72 + 6
          }%`;
          document.querySelector(
            ".slideUpText"
          ).style.transform = ` translate3d(0vw, 0vh ,0px) scale3d(${
            1 - progress
          }, ${
            1 - progress
          }, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
          document.querySelector(".slideUpText").style.opacity = 1 - progress;
        },
      },
    });
    gsap.to(".slideup2", {
      scrollTrigger: {
        trigger: ".slideup2",
        start: "top 40%",
        end: "bottom 40%",
        //markers: true,
        scrub: true,
        toggleActions: "play reverse play reverse",
        onUpdate: (self) => {
          const progress = self.progress;
          document.querySelector(".slideup2").style.top = `${
            82 - progress * 82 + progress * 10 + 6
          }%`;
          document.querySelector(".slideup1Txt").style.opacity = 1 - progress;
          document.querySelector(
            ".slideup1Txt"
          ).style.transform = `translate3d(0vw, 0vh,0px) scale3d(${
            1 - progress
          }, ${
            1 - progress
          }, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
        },
      },
    });
    gsap.to(".slideup3", {
      scrollTrigger: {
        trigger: ".slideup3",
        start: "top 0%",
        end: "bottom 0%",
        //markers: true,
        scrub: true,
        toggleActions: "play reverse play reverse",
        onUpdate: (self) => {
          const progress = self.progress;
          // document.querySelector(".slideup3").style.transform = ` translate3d(0vw, ${
          //   42 - progress * 42 - 63
          // }vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
          document.querySelector(".slideup3").style.top = `${
            92 - progress * 92 + progress * 20 + 6
          }%`;
          document.querySelector(".slideup2Txt").style.opacity = 1 - progress;
          document.querySelector(
            ".slideup2Txt"
          ).style.transform = ` translate3d(0vw, 0vh,0px) scale3d(${
            1 - progress
          }, ${
            1 - progress
          }, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
        },
      },
    });
  }, []);

  return (
    <div className="slideUpAnimation">
      <Container fluid className="slideUpCont">
        <Row className="rowSlideContent">
          <Col md={5} className="slideupleft" id="slideupleft">
            <h2 className="slideUpText">
              <span className="headerText">iGaming Payment Solutions </span>{" "}
              <br />
              Elevate your users' experience with smooth, secure iGaming
              payments and enjoy top-notch approval rates with steady stream of
              repeat customers
            </h2>
            <div className="slideUpCard slideup1">
              <div className="cardimg">
                {<img src={globe}></img>}
                Don’t get restricted to trusted traffic
              </div>
              <div className="slideup1Txt">
                <h4></h4>
                <p>
                  We bring solutions that build your Businesses. With a huge
                  network of banks and our inbuilt tools to mitigate the
                  associated risk with FTD traffic
                </p>
              </div>
            </div>
            <div className="slideUpCard slideup2">
              <div className="cardimg">
                {<img src={server}></img>}We’ve got you covered!
              </div>
              <div className="slideup2Txt">
                <h4>Cards, APMs all with single API connection</h4>
                <p>
                  Diversify your list of payment methods and offer the way your
                  customers want to pay
                </p>
              </div>
            </div>
            <div className="slideUpCard slideup3">
              <div className="cardimg">
                {<img src={analytics}></img>}We serve you the way you run
              </div>
              <div className="slideup3Txt">
                <h4></h4>
                <p>
                  We let you manage your Payments on your fingertips. You’re
                  just a few clicks away to your daily reports and analytics
                </p>
              </div>
            </div>
          </Col>
          <Col md={7} className="slideupright" id="slideupright">
            <div className="innerCard videoCard">
              <video muted={true} preload="auto" id="scrollVideo">
                {" "}
                <source src={process} type="video/mp4" />
              </video>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SlideContent;
